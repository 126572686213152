import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Img from 'gatsby-image'

import SectionHeader from './section-header'

import style from '../styles/intro.module.css'

const Intro = () => {
  const data = useStaticQuery(graphql`
    query {
      file (
        relativePath: { eq: "avatar.jpg" },
        sourceInstanceName: { eq: "images" },
      ) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const { childImageSharp } = data.file

  return (
    <>
      <SectionHeader>
        <div className={style.intro}>
          <h1>Hi!</h1> <Img fluid={childImageSharp.fluid} className={style.avatar} />
        </div>
        <p>
          I'm Ethan Li, a fourth-year Bioengineering PhD student in{' '}
          <a href="http://prakashlab.stanford.edu/">Manu Prakash's lab</a> at
          Stanford University, where I design and build hardware+software systems
          for global health and frugal science. I go by they/them pronouns.
        </p>
        <p>
          I'm currently exploring the possibilities of robotic microscopy networks
          for global collaboration and learning. Specifically, I'm prototyping
          an online collaboration platform for the <a href='https://www.planktoscope.org/'>
            PlanktoScope project
          </a> to build a global community where people will collect, watch, and discuss real-time
          measurements of plankton biodiversity in diverse environments across the world,
          generating and annotating ecological datasets in the process.  My goal is to enable
          microscopic measurement of the spatiotemporal dynamics of ecologies at a global scale,
          as well as to offer people playful and social ways of exploring ecology and nature.
        </p>
        <p>
          I've also worked on projects investigating and developing open-source medical devices
          as a strategy to support cooperative development of locally-appropriate medical devices
          for global health problems. Although I'm not currently working on projects in this area,
          I'm deeply interested in challenges and strategies for local development, production,
          and ownership of medical devices and scientific instruments around the world.
        </p>
        <p>
          If you want to chat about any of these topics, you can email me
          at <a href="mailto:ethanli@stanford.edu">ethanli@stanford.edu</a>.
          If you want to check out my background, please refer to{' '}
          <a href={
            'https://docs.google.com/document/d/108EKsWw4GVyhvMiCY9wna' +
            'VAvcOXCppiHDL8jIVvOtcA/edit'}
          >
            my CV
          </a>.
        </p>
      </SectionHeader>
    </>
  )
}

export default Intro
