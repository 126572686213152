import React, { Component } from 'react'

export default class Comments extends Component {

  constructor(props){ 
    super(props)
    this.commentBox = React.createRef() // Creates a reference to inject the <script> element
  }

  componentDidMount () {
    const utteranceTheme = 'github-light'
    const scriptEl = document.createElement('script')
    scriptEl.setAttribute('src', 'https://giscus.app/client.js')
    scriptEl.setAttribute('data-repo', 'ethanjli/ethanj.li')
    scriptEl.setAttribute('data-repo-id', 'MDEwOlJlcG9zaXRvcnkzMjA1MjIwNjc=')
    scriptEl.setAttribute('data-category', 'Announcements')
    scriptEl.setAttribute('data-category-id', 'DIC_kwDOExrHU84CPQgq')
    scriptEl.setAttribute('data-mapping', 'pathname')
    scriptEl.setAttribute('data-reactions-enabled', '1')
    scriptEl.setAttribute('data-emit-metadata', '0')
    scriptEl.setAttribute('data-input-position', 'bottom')
    scriptEl.setAttribute('data-theme', 'light')
    scriptEl.setAttribute('data-lang', 'en')
    scriptEl.setAttribute('issue-term', 'pathname')
    scriptEl.setAttribute('crossorigin','anonymous')
    scriptEl.setAttribute('async', true)
    this.commentBox.current.appendChild(scriptEl)
  }

  render() {
    return (
      <div className='comment-box-wrapper'>
        <div ref={this.commentBox} className='comment-box' />
        {/* Above element is where the comments are injected */}
      </div>
    )
  }
}
